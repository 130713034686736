body {
  color: RGB(250, 250, 250);
  background-color: RGB(20, 80, 160);

  font-family: 'Roboto', 'Poppins', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.centering {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hidden {
  display: none;
}

.simple-button {
  flex: none;
  margin: 0 5px;
  padding: 10px 20px;
  border: 1px solid white;
  background-color: transparent;
  color:white;
  box-sizing: border-box;
  text-decoration:none;
  font-size: medium;
  text-align:center;
  transition: all 0.2s;
}

.app {
  width:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* border: 1px white solid; */
}

.header {
  margin: 5px;
  font-family: 'Roboto Mono', 'Fira Mono', 'Consolas', 'Lucida Console', Courier, monospace;
  font-size: x-large;
}

.header h1 {
  margin: 10px 0 0 0;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1080px;
}

.hint-img {
  width: 50%;
  height: 150px;
  object-fit: contain;
  object-position: bottom;
  width: 600px;
}

.audio-player button {
  border: 0;
  background: transparent;
  box-sizing: border-box;
  width: 0;
  height: 74px;
  padding: 0;

  margin-bottom: 5px;

  border-color: transparent transparent transparent RGB(250, 250, 250);
  transition: 100ms all ease;
  cursor: pointer;

  /* play state */
  border-style: solid;
  border-width: 37px 0 37px 60px;
}

.audio-player button.pause {
  border-style: double;
  border-width: 0px 0 0px 60px;
  padding: 0;
  /* margin-bottom: 5px; */
}

.audio-player button:hover {
  border-color: transparent transparent transparent RGB(210, 230, 250);
}

.explanation {
  /* width: 600px; */
  flex-direction: column;
}

.explanation .word{
  text-transform: capitalize;
}

.answer-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 600px; */
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.answer-input .row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}

.answer-input input {
  font-size: 1.5rem;
  box-shadow: none;
  border: 0;
  border-bottom: 2px solid RGB(150, 150, 150);
  outline: 0;
  background: transparent;
  transition: border-color 0.2s;
  width: 100%;
  padding: 0;
  margin: 0;
  color: white;
}

.answer-input input::placeholder{
  color: white;
}

.answer-input input.correct {
  border-bottom: 2px solid RGB(150, 250, 150);
}

.answer-input input.incorrect {
  border-bottom: 2px solid RGB(250, 150, 150);
}

.simple-button:hover {
  color: black;
  background-color: white;
  
}

.result {
  height: 72px;
  font-weight: 900;
}

.controlls {
  display: flex;
  justify-content: center;
  /* width: 600px; */
}

.info {
  font-size: 1.5rem;
  font-weight: 900;
  /* width: 600px; */
}

@media screen and (max-width: 1080px) {
  .main {
    width: 800px;
  }
}

@media screen and (max-width: 800px) {
  .main {
    width: 600px;
  }
}

@media screen and (max-width: 600px) {
  .main {
    width: 480px;
  }
}

@media screen and (max-width: 480px) {
  .main {
    width: 320px;
  }
}
